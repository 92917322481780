$(document).ready(function() {
  $(window).resize();
  $(".js-cd-panel-trigger").on("click", function() {
    $(".hamburger").toggleClass("is-active");
    $(".js-cd-panel-main").toggleClass("cd-panel--is-visible");
  });
  $(".cd-panel").on("click", function(e) {
    if ($(e.target).hasClass("cd-panel--is-visible")) {
      $(".js-cd-panel-main").removeClass("cd-panel--is-visible");
      $(".hamburger").toggleClass("is-active");
    }
  });
  $("a.circle, lottie-player").on("click", function(e) {
    e.preventDefault();
    $.scrollTo(".maincontent", 400, { offset: -70 });
  });

  $(window).resize(function() {
    $("#intro").css("height", $(window).height());
  });

  $(window).scroll(function() {
    if ($(window).scrollTop() >= 200) {
      $("lottie-player").hide();
    } else {
      $("lottie-player").show();
    }
  });
});
